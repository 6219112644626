<template>
  <div class="row">
    <input-text
      v-model="filtro.nome"
      class="col-6"
      :label="$t('modulos.servico_calibracao.filtro.nome')"
      :placeholder="$t('modulos.servico_calibracao.filtro.nome')"
      em-filtro
    />
    <input-text
      v-model="filtro.codigo"
      class="col-6"
      :label="$t('modulos.servico_calibracao.filtro.codigo')"
      :placeholder="$t('modulos.servico_calibracao.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.localExecucao"
      class="col-6"
      :label="$t('modulos.servico_calibracao.filtro.local_execucao')"
      :placeholder="$t('modulos.servico_calibracao.filtro.local_execucao')"
      em-filtro
    />
    <input-text
      v-model="filtro.tipoInstrumento"
      class="col-6"
      :label="$t('modulos.servico_calibracao.filtro.tipo_instrumento')"
      :placeholder="$t('modulos.servico_calibracao.filtro.tipo_instrumento')"
      em-filtro
    />
    <input-text
      v-model="filtro.modeloInstrumento"
      class="col-12"
      :label="$t('modulos.servico_calibracao.filtro.modelo_instrumento')"
      :placeholder="$t('modulos.servico_calibracao.filtro.modelo_instrumento')"
      em-filtro
    />
  </div>
</template>
<script>
export default {
  props: {
    filtro: {},
  },
};
</script>

