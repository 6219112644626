<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.servico_manutencao.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.servico_manutencao.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('ServicoManutencao', 'Inserir')"
      :sem-filtro="!buscarPermissao('ServicoManutencao', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <div class="d-flex">
      <botao-padrao
        v-if="podeExcluir"
        class="my-2 mr-2"
        color="primary"
        @click="abrirAtualizarPrecos"
      >
        {{ $t('modulos.servico_manutencao.botoes.atualizar_preco') }}
      </botao-padrao>
      <botao-padrao
        v-if="podeExcluir && buscarPermissao('ServicoManutencao', 'Deletar')"
        class="my-2"
        outlined
        color="danger"
        @click="excluirRegistros"
      >
        <v-icon>$mdiTrashCanOutline</v-icon>
        {{ $t('geral.botoes.excluir') }}
      </botao-padrao>
    </div>

    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      filtros-com-colunas
      :mostrar-seletor="buscarPermissao('ServicoManutencao', 'Deletar')"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:valorServico="{ slotProps }">
        <div class="alinhar-direita">
          {{ formatacaoMoeda(slotProps.data.valorServico) }}
        </div>
      </template>
      <template v-slot:faixa="{ slotProps }">
        <div
          v-if="slotProps.data.amplitudeMinima || slotProps.data?.amplitudeMaxima"
          class="alinhar-direita"
        >
        {{
          faixaFormatada(`(${ slotProps.data?.amplitudeMinima ? slotProps.data.amplitudeMinima + ' a ' : ''}
        ${ slotProps.data?.amplitudeMaxima || '...'})
        ${slotProps.data?.unidadeMedida?.simbolo || '' }`)
        }}
        </div>
      </template>

      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>

          <dropdown-padrao-item
            v-if="buscarPermissao('ServicoManutencao', 'Editar')"
            text
            color="secondary"
            @click="abrirEditar(slotProps.data)"
          >
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>

          <dropdown-padrao-item
            text
            color="secondary"
            :erros="erros"
            @click="abrirDuplicarServico(slotProps.data)"
          >
            {{ $t('modulos.servico_manutencao.botoes.duplicar_servico') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
      <template v-slot:tiposInstrumentosView="{ slotProps }">
        <coluna-multiplos-itens
          v-if="slotProps.data?.tiposInstrumentos?.length"
          custom-style="max-width: 500px;"
          :item="MontarViewColunasItens(slotProps.data?.tiposInstrumentos)"
        />
      </template>
      <template v-slot:modelosInstrumentosView="{ slotProps }">
        <coluna-multiplos-itens
          v-if="slotProps.data?.modelosInstrumentos?.length"
          custom-style="max-width: 500px;"
          :item="MontarViewColunasItens(slotProps.data?.modelosInstrumentos)"
        />
      </template>
    </tabela-padrao-prime-vue>

    <duplicar-servico-modal
      ref="modal-duplicar-servico"
      @salvar-novo="duplicarServicoManutencao"
    />
    <atualizar-preco-modal
      ref="modal-atualizar-precos"
      @salvar-novo="atualizarPrecos"
    />
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import ServicoService from '@common/services/cadastros/ServicoService';
import Filtro from './filtro.vue';
import DuplicarServicoModal from './components/modais/DuplicarServicoModal.vue';
import AtualizarPrecoModal from './components/modais/AtualizarPrecoModal.vue';
import { AtualizarPrecoServicoModel } from '@common/models/cadastros/AtualizarPrecoServicoModel';
import ColunaMultiplosItens from '@/components/tabelas/ColunaMultiplosItens.vue';
import helpers from '@common/utils/helpers';

export default {
  components: {
    CabecalhoPagina,
    DuplicarServicoModal,
    AtualizarPrecoModal,
    ColunaMultiplosItens,
    Filtro,
  },
  data() {
    return {
      formAtualizarPrecoServico: new AtualizarPrecoServicoModel({}),
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.servico_manutencao.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'nome',
            text: this.$t('modulos.servico_manutencao.tabela.nome'),
            sortable: true,
          },
          {
            value: 'tiposInstrumentosView',
            text: this.$t('modulos.servico_calibracao.tabela.tipos_instrumentos'),
            sortable: false,
          },
          {
            value: 'modelosInstrumentosView',
            text: this.$t(
              'modulos.servico_calibracao.tabela.modelos_instrumentos'
            ),
            sortable: false,
          },
          {
            value: 'localExecucao.nome',
            text: this.$t('modulos.servico_manutencao.tabela.local_execucao'),
            sortable: true,
          },
          {
            value: 'faixa',
            text: this.$t('modulos.servico_manutencao.tabela.faixa'),
            sortable: true,
          },
          {
            value: 'valorServico',
            text: this.$t('modulos.servico_manutencao.tabela.valor_servico'),
            sortable: true,
            formatter: (v) => helpers.formatarMoeda(v),
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      erros: [],
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'ServicoManutencao', 'Listar');
    const filtros = helpers.buscarStateFiltros(this);
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.servico_manutencao.titulos.listagem')
    );
  },
  methods: {
    MontarViewColunasItens(lista_dados) {
      return {
        value: lista_dados?.map((v) => {
          return { nome: v?.nome };
        }),
      };
    },
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina,
      tipoServico = 2
    ) {
      this.tabela.porPagina = porPagina;
      helpers.salvarStateFiltros(this, {
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });

      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        tipoServico: tipoServico,
        ...this.filtroAplicado,
        sort: this.colunaOrdenada,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      ServicoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens =
            res.data.totalItems ?? this.tabela.dados.length;

          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        this.tabela.selecionados.forEach((item) => {
          ServicoService.excluir(item.id)
            .then(() => {
              this.listarRegistros(
                this.tabela.paginaAtual,
                this.tabela.porPagina
              );
              this.toastSucesso(
                this.$t(`modulos.servico_manutencao.exclusao_sucesso`)
              );
              this.tabela.selecionados = [];
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
            });
        });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'servico-manutencao-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'servico-manutencao-editar',
        params: { id: item.id },
      });
    },
    abrirDuplicarServico: function (item) {
      this.$refs['modal-duplicar-servico'].abrirModal(item);
    },
    abrirAtualizarPrecos: function () {
      this.formAtualizarPrecoServico = {
        ...this.formAtualizarPrecoServico,
        servicos: this.tabela.selecionados.map((item) => item.id),
      };
      this.$refs['modal-atualizar-precos'].abrirModal(
        this.formAtualizarPrecoServico
      );
    },
    duplicarServicoManutencao: function (form) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ServicoService.duplicar(form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.servico_manutencao.cadastro_sucesso`)
          );
          this.listarRegistros();
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    atualizarPrecos: function (formAtualizarPrecoServico) {
      ServicoService.atualizarPreco(formAtualizarPrecoServico)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.servico_manutencao.cadastro_sucesso`)
          );
          this.listarRegistros();
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.tabela.selecionados = [];
        });
    },
    formatacaoMoeda(valor) {
      if (valor) return helpers.formatarMoeda(valor);
    },
    faixaFormatada(value) {
      return String(value).replaceAll('.', ',');
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
<style lang="scss">
.alinhar-direita {
  display: flex;
  justify-content: right;
}
</style>
